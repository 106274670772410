import _defineProperty from "/vercel/path0/node_modules/.pnpm/@babel+runtime@7.15.4/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

export var breakpoints = {
  mobile: 990,
  desktop: 10000
};
var colorVariables = {
  // Primary
  colorPrimaryBg: '#E6F4FF',
  colorPrimaryBgHover: '#BAE0FF',
  colorPrimaryBorder: '#91CAFF',
  colorPrimaryBorderHover: '#69B1FF',
  colorPrimaryHover: '#4096FF',
  colorPrimaryBase: '#195AFF',
  colorPrimaryActive: '#0958D9',
  colorPrimaryTextHover: '#003EB3',
  colorPrimaryText: '#003EB3',
  colorPrimaryTextActive: '#001D66',
  // Success
  colorSuccessBg: '#F6FFED',
  colorSuccessBgHover: '#D9F7BE',
  colorSuccessBorder: '#B7EB8F',
  colorSuccessBorderHover: '#95DE64',
  colorSuccessHover: '#73D13D',
  colorSuccessBase: '#52C41A',
  colorSuccessActive: '#389E0D',
  colorSuccessTextHover: '#237804',
  colorSuccessTextActive: '#092B00',
  // Warning
  colorWarningBg: '#FFFBE6',
  colorWarningBgHover: '#FFF1B8',
  colorWarningBorder: '#FFE58F',
  colorWarningBorderHover: '#FFD666',
  colorWarningHover: '#FFC53D',
  colorWarningBase: '#FAAD14',
  colorWarningActive: '#D48806',
  colorWarningTextHover: '#AD6800',
  colorWarningText: '#874D00',
  colorWarningTextActive: '#613400',
  // Error
  colorErrorBg: '#FFF1F0',
  colorErrorBgHover: '#FFCCC7',
  colorErrorBorder: '#FFA39E',
  colorErrorBorderHover: '#FF7875',
  colorErrorHover: '#FF7875',
  colorErrorBase: '#FF4D4F',
  colorErrorActive: '#CF1322',
  colorErrorTextHover: '#A8071A',
  colorErrorText: '#820014',
  colorErrorTextActive: '#5C0011',
  // Info
  colorInfoBg: '#E6F4FF',
  colorInfoBgHover: '#BAE0FF',
  colorInfoBorder: '#91CAFF',
  colorInfoBorderHover: '#69B1FF',
  colorInfoHover: '#4096FF',
  colorInfoBase: '#1677FF',
  colorInfoActive: '#0958D9',
  colorInfoTextHover: '#003EB3',
  colorInfoText: '#002C8C',
  colorInfoTextActive: '#001D66',
  // Text & Icons
  colorText: '#000000E0',
  colorTextHeading: '#000000E0',
  colorTextDescription: '#00000073',
  colorTextPlaceholder: '#00000040',
  colorTextDisabled: '#00000040',
  colorIcon: '#00000073',
  colorIconHover: '#ffffffd9',
  // Backgrounds
  colorBgContainer: '#ffffff',
  colorBgContainerDisabled: '#0000000A',
  colorBgLayout: '#F5F5F5',
  colorBgElevated: '#FFFFFF',
  controlItemBgHover: '#0000000a',
  controlItemBgActiveHover: '#BAE0FF',
  // Borders & dividers
  colorBorder: '#00000026',
  colorBorderSecondary: '#0000000F',
  colorSplit: '#00000026',
  //Shadow
  colorShadow: '#AFB7BB66'
};
export var styleVariables = {
  colors: _objectSpread({
    gray1: '#ffffff',
    gray2: '#fafafa',
    gray3: '#f2f2f2',
    gray4: '#f0f0f0',
    gray5: '#e9e9e9',
    gray6: '#e3e4e5',
    gray7: '#e3e4e6',
    gray8: '#dadce0',
    gray9: '#d6d6d6',
    gray10: '#9A9A9A',
    gray11: '#616161',
    gray12: '#333333',
    gray13: '#000000',
    grey14: '#e1e5e9',
    grey30: '#F0F3F5',
    grey70: '#80888C',
    grey100: '#1C1E20',
    transparentGray6: 'rgba(0, 0, 0, 0.12)',
    lightBlue: '#F8F9FC',
    blue1: '#e6f2ff',
    blue2: '#1890ff',
    blue3: '#1a5aff',
    blue4: '#1616b9',
    blue5: '#f2f6fc',
    blue6: '#1A5AFF',
    blue80: '#00407F',
    primary20: '#E2F0FF',
    primary60: '#0B48E5',
    cyan1: '#1a5a77',
    gold1: '#fa8c15',
    gold2: '#ff8a00',
    red1: '#ffccc7',
    red2: '#ff4d4f',
    red3: '#f5222d',
    red4: '#CF1322',
    red5: '#FFF1F0',
    red10: '#FFF3F4',
    red40: '#FF3742',
    red60: '#C70615',
    green1: '#3a8814',
    green2: '#E8FCD5'
  }, colorVariables),
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '16px'
  },
  images: {
    brand: ''
  },
  font: {
    fontSFProDisplay: 'SF Pro Display',
    fontSizeSmall: '14px',
    fontSizeOriginal: '16px',
    fontSizeMedium: '20px',
    fontSizeLarge: '28px',
    fontSizeSupport: '12px',
    fontWeightNormal: '400',
    fontWeightStrong: '600'
  },
  responsive: {
    mobile: 640,
    tablet: 800,
    largeScreen: 1200
  },
  logo: {
    primary: {
      path: ''
    },
    white: {
      path: ''
    }
  },
  theme: 'snow',
  modules: {
    toolbar: [['bold', 'italic', 'underline']],
    clipboard: {
      matchVisual: false
    }
  },
  formats: ['bold', 'italic', 'underline', 'variable']
};